@font-face {
  font-family: 'Steelfish';
  src: local('SteelfishRg-Bold'),
      url('./fonts/SteelfishRg-Bold.woff2') format('woff2'),
      url('./fonts/SteelfishRg-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Steelfish';
  src: local('SteelfishRg-Regular'),
      url('./fonts/SteelfishRg-Regular.woff2') format('woff2'),
      url('./fonts/SteelfishRg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --background: #000000;
  --restrict-container: 1200px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Steelfish", san-serif;
}

html {
  background: var(--background);
  color: white;
  font-size: calc(60% + 0.8vmin);
}

html, body {
  width: 100%;
}

main {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
}

section {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.hero {
  width: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0.5) 79%, rgba(0,0,0,1) 100%), url('./img/pexels-karolina-grabowska-6634140.webp') no-repeat;
  background-size: cover;
  background-position: bottom center, center center;
}

.hero .flyer {
  object-fit: contain;
  max-width: calc(var(--restrict-container)/1.2);
  width: 100%;
  height: auto;
}

.content {
  max-width: var(--restrict-container);
  width: 100%;
  margin: 0 auto;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header h1 {
  text-transform: uppercase;
  font-size: 3rem;
  text-align: center;
}

.header ion-icon {
  font-size: 2rem;
  margin: 1rem;
  color: rgba(255, 255, 255, 0.33);
  transition: color 0.33s cubic-bezier(0.77, 0, 0.175, 1);
}

.header a:hover ion-icon {
  color: rgba(255, 255, 255, 1);
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer .big {
  font-size: 7rem;
  min-width: 6rem;
}

@media (min-width: 576px) {
  .timer .big {
    font-size: 10rem;
    min-width: 8rem;
  }  
}

@media (min-width: 640px) {
  .timer .big {
    font-size: 13rem;
    min-width: 9rem;
  }  
}

@media (min-width: 768px) {
  .timer .big {
    font-size: 15rem;
    min-width: 10rem;
  }  
}

@media (min-width: 960px) {
  .timer .big {
    font-size: 18rem;
    min-width: 12rem;
    margin-top: -1rem;
  }  
}

@media (min-width: 1366px) {
  .timer .big {
    font-size: 23rem;
    min-width: 12rem;
    margin-top: -3rem;
  }  
}

@media (min-width: 1600px) {
  .timer .big {
    font-size: 25rem;
    min-width: 17rem;
  }  
}

.timer .spacer {
  opacity: 0;
  pointer-events: none;
}

.timer .spacer .big {
  min-width: auto;
  position: relative;
  top: -3rem;
}

.timer .descriptor {
  margin-top: 0rem;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
}

@media (min-width: 640px) {
  .timer .descriptor {
    margin-top: -1.5rem;
  }  
}

.enter {
  font-size: 2rem;
  text-transform: uppercase;
  padding: 0.5rem 3rem;
  border: 2px solid white;
  background: none;
  color: white;
  margin: 2rem 0 5rem;
  text-decoration: none;
  display: inline-block;
}

.enter[disabled] {
  opacity: 0.5;
  pointer-events: none;
}